<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="header-block d-flex align-center justify-space-between pb-8 pb-sm-15">
      <div class="d-flex align-center">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header pr-5 pr-sm-15">number pool</div>
        </portal>
        
        <ActionButton @click="closeAction">Manage pools</ActionButton>
      </div>
      
      <div>
        <CancelButton @click="closeAction">Close</CancelButton>
      </div>
    </div>
  
    <v-card
      class="create-card cardBg py-15 px-12 rounded-20"
      :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
      flat
      outlined
    >
      <v-row class="setup-content d-block d-md-flex align-center" no-gutters>
        <v-col cols="12" md="3" lg="2" class="mb-0 mb-md-20">
          <TextDescription :description="description[0]" />
        </v-col>
      
        <v-col cols="12" md="9" lg="10">
          <v-form ref="form" v-model="validateForm">
            <v-row no-gutters>
              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="5" md="3"
              >
                <span class="font-normal nameField--text">Pool name</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="poolName"
                ></portal-target>
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9">
                <FormField
                  v-model="formDataPool.name"
                  class="field-container"
                  :rules="rules"
                  :loading="loadingDetailPool"
                >
                  <template #message="{ key, message }">
                    <portal to="poolName" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="5" md="3"
              >
                <span class="font-normal nameField--text">Country</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="countryName"
                ></portal-target>
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9">
                <AutocompleteField
                  v-model="formDataPool['country_id']"
                  class="field-container"
                  :items="countries"
                  item-text="name"
                  item-value="id"
                  :rules="rules"
                  :loading="loadingDetailPool"
                  checkBg
                  @change="changeCountry"
                >
                  <template #message="{ key, message }">
                    <portal to="countryName" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </AutocompleteField>
              </v-col>
            
              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10 text-sm-end"
                cols="12" sm="5" md="3"
              >
                <span class="font-normal nameField--text">Closed browser delay</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="browserDelay"
                ></portal-target>
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9">
                <FormField
                  v-model="formDataPool['closed_delay']"
                  class="field-container"
                  type="number"
                  placeholder="30"
                  :rules="rules"
                  :loading="loadingDetailPool"
                  @keypress="onlyNumbers"
                >
                  <template #message="{ message }">
                    <portal to="browserDelay" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="5" md="3"
              >
                <span class="font-normal nameField--text">Idle limit</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="idleLimit"
                ></portal-target>
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9">
                <FormField
                  v-model="formDataPool['idle_limit']"
                  class="field-container"
                  type="number"
                  placeholder="300"
                  :rules="rules"
                  :loading="loadingDetailPool"
                  @keypress="onlyNumbers"
                >
                  <template #message="{ message }">
                    <portal to="idleLimit" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col
                class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                cols="12" sm="5" md="3"
              >
                <span class="font-normal nameField--text">Pool size</span>
                <portal-target
                  class="field-message font-normal error--text text-sm-right"
                  name="poolSize"
                ></portal-target>
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9">
                <FormField
                  v-model="formDataPool['pool_size']"
                  class="field-container"
                  :rules="rules"
                  :loading="loadingDetailPool"
                  type="number"
                  @keypress="onlyNumbers"
                >
                  <template #message="{ message }">
                    <portal to="poolSize" v-if="!!message">
                      {{ message }}
                    </portal>
                  </template>
                </FormField>
              </v-col>
            
              <v-col
                v-if="!poolId"
                class="text-sm-end font-normal nameField--text pr-sm-11 pb-4 pb-sm-0"
                cols="12" sm="5" md="3"
              >
                <span class="font-normal nameField--text">Auto buy</span>
              </v-col>
              <v-col class="pb-10" cols="12" sm="7" md="9" v-if="!poolId">
                <SwitchLg
                  v-model="formDataPool['auto_buy']"
                  bgColor="switcherViolet"
                />
              </v-col>

              <v-expand-transition>
                <v-col cols="12" v-if="formDataPool['auto_buy'] || !!this.poolId">
                  <v-row no-gutters>
                    <v-col
                      class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10 text-sm-end"
                      cols="12" sm="5" md="3"
                    >
                      <span class="font-normal nameField--text">Prefix</span>
                      <portal-target
                        class="field-message font-normal error--text text-sm-right"
                        name="autoBuyPrefix"
                      ></portal-target>
                    </v-col>
                    <v-col class="pb-10" cols="12" sm="7" md="9">
                      <FormField
                        v-model="formDataPool.pattern"
                        class="field-container"
                        type="number"
                        :prefix="numCode"
                        :loading="loadingDetailPool"
                        @keypress="onlyNumbers"
                      >
                        <template #message="{ message }">
                          <portal to="autoBuyPrefix" v-if="!!message">
                            {{ message }}
                          </portal>
                        </template>
                      </FormField>
                    </v-col>
                  </v-row>
                </v-col>
              </v-expand-transition>
            
              <v-col cols="12" sm="7" md="9" offset="0" offset-sm="5" offset-md="3" class="mt-2 mt-sm-8">
                <CancelButton class="mr-8 mr-sm-10" @click="closeAction">Cancel</CancelButton>
              
                <ActionButton :loading="loadingAction" @click="submitForm">
                  {{ poolId ? 'Update' : 'Create' }}
                </ActionButton>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'
import TextDescription from '@/components/description/TextDescription'
import FormField from '@/components/inputs/FormField'
import AutocompleteField from '@/components/select/AutocompleteField'
import SwitchLg from '@/components/controls/SwitchLg'

import { getDetailPool, createPool, updatePool } from '@/api/pool-routes'

export default {
  name: 'DetailPool',
  components: { ActionButton, CancelButton, TextDescription, FormField, AutocompleteField, SwitchLg },
  data: () => ({
    validateForm: true,
    formDataPool: {
      name: null,
      'country_id': null,
      'closed_delay': 30,
      'idle_limit': 300,
      'pool_size': 5,
      'auto_buy': null,
      pattern: null,
    },
    rules: [],
    numberType: [
      { id: 1, name: 'Local' },
      { id: 2, name: 'Toll-Free' },
      { id: 3, name: 'Any' },
    ],
    description: [
      {
        title: 'Setup pool numbers',
        text: [],
      },
    ],
    loadingAction: false,
    loadingDetailPool: false,
    poolId: null,
    numCode: '',
  }),
  computed: {
    ...mapState({
      countries: state => state.countries,
    }),
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.poolId = this.$route.params.id
      this.loadDetailPool()
    }
  },
  methods: {
    closeAction() {
      this.$router.push({ name: 'ManagePools' })
    },
    changeCountry(countryId) {
      const country = this.countries.find(c => c.id === countryId)
      if (country) {
        this.numCode = country['num_code']
      }
    },
    async loadDetailPool() {
      this.loadingDetailPool = true

      const { success, payload, message } = await getDetailPool(this.poolId)

      if (success) {
        this.formDataPool = {
          name: payload.name,
          country_id: payload['country_id'],
          'closed_delay': payload['closed_delay'],
          'idle_limit': payload['idle_limit'],
          'pool_size': payload['pool_size'],
          pattern: payload.pattern,
        }
        this.changeCountry(payload['country_id'])
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingDetailPool = false
    },
    async submitForm() {
      if (!this.formDataPool['auto_buy'] && !this.poolId) {
        this.formDataPool.pattern = null
      }

      await this.validationForm()
      if (this.$refs.form.validate()) {
        if (this.poolId) {
          this.updatePool()
        } else {
          this.createPool()
        }
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    async createPool() {
      this.loadingAction = true

      const { success, message } = await createPool(this.formDataPool)

      if (success) {
        this.$notify({ type: 'success-bg', text: message ? message : 'Pool was created!' })
        this.closeAction()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    async updatePool() {
      this.loadingAction = true

      const { success, message } = await updatePool({
        poolId: this.poolId,
        formData: this.formDataPool
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message ? message : 'Pool was updated!' })
        this.closeAction()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    onlyNumbers(event) {
      const regex = new RegExp('^[0-9]+$')
      const key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
      if (!regex.test(key)) {
        event.preventDefault()
        return false
      }
    },
  },
}
</script>
